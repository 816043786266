a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  /* vertical-align: baseline; */
  /* vertical-align: middle; */
}

*,
:after,
:before {
  box-sizing: border-box;
}

body,
html,
#root {
  width: 100%;
  height: 100%;
}

html {
  font-family: sans-serif;
}

body {
  font-weight: 400;
  /* font-size: 1em; */
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif;
}

a {
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

a:hover,
a:focus {
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
  box-sizing: content-box;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 16px;
  margin-top: 1.5em;
}

h2 {
  font-size: 24px;
}

ol,
ul {
  margin-bottom: 1em;
  margin-top: 0;
  padding-left: 2em;
}

.operationCloumn {
  color: #1da57a;
  cursor: pointer;
}

.imageStyle {
  display: flex;
  justify-content: center;
}

.top-area {
  display: flex;
  margin-bottom: 20px;
}

.btn-container {
  width: 50%;
}

.btn-container-only {
  width: 100%;
}

.search-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.tableImage {
  width: 85px;
  height: 85px;
  margin: 0px auto;
  box-sizing: border-box;
  display: table-cell;
  border-style: dashed;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  object-fit: cover;
}

.tableImage-no-center {
  width: 75px;
  height: 75px;
  box-sizing: border-box;
  display: table-cell;
  border-style: dashed;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  object-fit: cover;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: " ";
  left: 0px;
}

.upload-tip {
  color: #bebebe;
  font-size: 12px;
}

.text-align-right {
  text-align: right;
}
