.login {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 600px;
  background: #F7F7F7;
}

.header {
  position: fixed;
  width: 100%;
  min-height: 50px;
  padding: 8px 0;
  color: #fff;
  background: #20232a;
}

.header-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

.header-wrapper header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  text-align: left;
}

.header-wrapper header a {
  display: flex;
  height: 34px;
  align-items: center;
  border: 0;
  font-size: 1.25em;
  font-weight: bold;
  color: #fff;
  flex-flow: row nowrap;
}

.header-wrapper header img {
  height: 100%;
  margin-right: 10px;
}

.nav-wrapper {
  position: relative;
  height: 34px;
  margin-left: auto;
}

.nav-wrapper nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  box-sizing: border-box;
}

@media only screen and (min-width: 1024px) {
  .nav-wrapper nav {
      position: relative;
      right: auto;
      top: auto;
      width: auto;
      height: auto;
      background: none;
  }
}

.nav-wrapper nav ul {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin-top: 50px;
  padding: 0;
  width: 100%;
  background: #61dafb;
  box-sizing: border-box;
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  .nav-wrapper nav ul {
      display: flex;
      flex-flow: row nowrap;
      margin: 0;
      padding: 0;
      width: auto;
      background: none;
  }
}

.nav-wrapper nav ul li {
  flex: 1 1 auto;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.nav-wrapper nav ul li a {
  display: flex;
  margin: 0;
  padding: 10px;
  align-items: center;
  box-sizing: border-box;
  color: inherit;
  font-size: .9em;
  height: 50px;
  justify-content: center;
  transition: background-color .3s;
}

.nav-wrapper nav ul li a:hover {
  color: #ffffff;
}

@media only screen and (min-width: 1024px) {
  .nav-wrapper nav ul li a {
      display: flex;
      margin: 0;
      padding: 6px 10px;
      height: 32px;
      line-height: 1.2em;
      border: 0;
      color: hsla(0,0%,100%,.8);
      font-size: 1em;
      font-weight: 300;
  }
}

.login-title {
  position: relative;
  top: 116px;
  text-align: center;
  margin-bottom: 30px;
  font-family: serif;
  font-size: 24px;
  color: #41464b;
  letter-spacing: 2px;
  font-weight: 700;
}

.content {
  width: 520px;
  height: auto;
  margin: 0 auto;
  padding: 60px 90px;
  position: relative;
  top: 116px;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 3px 0 rgba(213,213,213,0.7);
  box-sizing: border-box;
  background-color: #fff;
}

.login-form {
  width: 100%;

  .input-tips {
    display: block;
    margin: 8px 0;
    font-size: 12px;
    line-height: 1;
    color: #a5a5a5;
  }

  .login-form-forgot {
    float: right;
  }
  
  .login-form-button {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    font-size: 14px;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: opacity 0.3s,border-color 0.3s;
    color: #fff;
    border-color: #41464b;
    background-image: linear-gradient( #41464b,#2c3033 );
    font-weight: bold;
    min-width: 100px;
    line-height: 40px;
    padding: 0 15px;

    &:hover{
      background-color: #363b3e;
    }

    &:hover{
      background-image: none;
    }

    &:active {
      opacity: 0.9;
    }

    &:focus {
      outline: none;
    }
  }

}

// :global(.login>.ant-form>.ant-form-item) {
//   height: 64px;
//   margin-bottom: 0;
// }

// .login>.ant-form-item-control>.ant-form-item-control-input {
//   border-radius: 2px;
//   background: #fff;
//   box-shadow: inset 0 1px 3px 0 #e5e5e5;
//   background-color: #fff;
//   box-sizing: border-box;
//   border-radius: 2px;
//   border: 1px solid #e5e5e5;
//   -webkit-transition: border-color 0.3s;
//   transition: border-color 0.3s;
//   width: 100%;
//   box-sizing: border-box;
//   color: #41464b;
//   font-size: 14px;
//   background: transparent;
//   vertical-align: top;
// }

.footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  position: absolute;
  bottom: 15px;

  a {
    color:#515a6e;

    &:hover{
      color: #57a3f3;
    }
  }
}

@media screen and (max-width: 768px) {

  .login {
    .content {
      width: inherit;
      padding: 20px 30px;
    }
  }

}