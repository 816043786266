.material-library {
  width: 100%;
  height: auto;
}
.displayFlex {
  display: table;
  .display-area {
    width: 75px;
    height: 75px;
    box-sizing: border-box;
    display: table-cell;
    border: 1px solid #d9d9d9;
    background-color: #f5f5f6;
    border-style: dashed;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;

    .display-images {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .upload-button {
    width: 104px;
    height: 104px;
    box-sizing: border-box;
    display: table-cell;
    border: 1px solid #d9d9d9;
    background-color: #f5f5f6;
    border-style: dashed;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    .upload-icon {
      font-size: 15px;
      color: #999;
    }

    .upload-text {
      font-size: 12px;
      color: #999;
    }
  }
}

.modal-body {
  padding: 0px;
  .content-container {
    width: 100%;
    height: 420px;

    .right-display {
      .right-top-upload-search {
        .upload-container {
          padding: 30px 0px 0px 30px;
        }
      }
      .right-middle-pic-list {
        width: 100%;
        padding: 20px;
        .card-container {
          width: 100px;
          height: 100px;
          padding: 0px;
          margin: 10px;
          position: relative;
          .checkbox {
            position: absolute;
            top: -4px;
          }
          .images {
            width: 100px;
            height: 100px;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
      .right-bottom-pagination {
        width: 100%;
        padding-top: 30px;
        padding-right: 30px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 70px;
      }
    }
  }

  .fotter-left-text {
    margin-right: 120px;
  }

  .cancel-checked {
    margin-right: 15px;
  }

  .delete {
    margin-right: 15px;
  }

  .move {
    margin-right: 30px;
  }
}
