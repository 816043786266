.btn_container {
  display: table;
  .upload_button {
    width: 104px;
    height: 104px;
    box-sizing: border-box;
    display: table-cell;
    border: 1px solid #d9d9d9;
    background-color: #f5f5f6;
    border-style: dashed;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    .upload_icon {
      font-size: 15px;
      color: #999;
    }
    .upload_text {
      font-size: 12px;
      color: #999;
    }
  }
}
