.display-flex-align-left {
  display: flex;
  justify-content: flex-start;

  .imageContainer {
    width: 104px;
    height: 104px;
    border: 1px dashed #d9d9d9;
    background-color: #f5f5f6;
  }

  .materialContainer {
    margin-left: 20px;
  }
}

.display-flex-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  .imageContainer {
    width: 104px;
    height: 104px;
  }

  .materialContainer {
    margin-left: 20px;
  }
}

.dispaly-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  .imageContainer {
    width: 104px;
    height: 104px;
  }
  .logoImageContainer {
    width: 115px;
    height: 50px;
  }
  .otherProductInfo {
    margin-left: 5px;
    .productNameContainer {
      height: 50%;
      align-items: center;
    }

    .productPriceContainer {
      height: 50%;
      align-items: center;
    }
  }
}

.bottom-submit-and-back {
  width: 86.8%;
  height: 40px;
  // position: fixed;
  // bottom:0px;
  display: flex;
  justify-content: center;
  align-items: center;
  // box-shadow: 0px -5px 5px #dddddd;
  background: white;
}

.sucaiku_container {
  width: 80%;
  border: 1px solid rgb(217, 217, 217);
  margin-top: 10px;
  .top_radio {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    .left_radio,
    .right_btn {
      width: 50%;
    }
    .right_btn {
      display: flex;
      justify-content: flex-end;
    }
  }
  .top_radio,
  .sucaiku_name,
  .sucaiku_detail,
  .sucaiku_tuji {
    width: 100%;
    padding: 0 20px;
  }
}
