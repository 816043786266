.material_top_container {
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #eee;
  border-radius: 10px 10px 0px 0px;
  .material_top_title {
    line-height: 55px;
    font-size: 16px;
    padding-left: 20px;
  }
}

.material_container_main {
  width: 100%;
  height: 500px;
  display: flex;
  font-size: 14px;

  .material_left_menu {
    width: 230px;
    overflow: auto;
    border-right: 1px solid #eee;
    .active {
      background-color: #f4f4f4;
    }
    .menu_item {
      width: 100%;
      height: 46px;
      padding: 0px 20px;
      line-height: 46px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 12px;
      .menu_info {
        width: 100%;
        height: 100%;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
        font-size: 12px;
      }
      .menu_operate {
        display: none;
        .operate_limiter {
          margin: 0px 10px;
        }
      }
    }
    .menu_item:hover {
      .menu_info {
        width: 70%;
      }
      background-color: #f4f4f4;
      .menu_operate {
        display: block;
        text-align: right;
      }
    }
    .menu_item_add {
      color: #5495f6;
      font-size: 12px;
    }
  }

  .material_right_container {
    width: 634px;
    height: 100%;
    .material_upload_and_search {
      width: 100%;
      height: 10%;
      border-right: 1px solid #eee;
      .material_upload {
        width: 50%;
        height: 100%;
        padding-left: 20px;
        display: flex;
        align-items: center;
      }
    }

    .material_images {
      width: 100%;
      height: 80%;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding: 0px 10px;
      overflow: auto;
      .image_name {
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }
      .card_container {
        width: 100px;
        height: 100px;
        padding: 0px;
        margin: 10px;
        position: relative;
        .checkbox {
          position: absolute;
          top: -4px;
        }
        .images {
          width: 100px;
          height: 100px;
          object-fit: cover;
          cursor: pointer;
          border-top: 1px solid #eee;
          border-left: 1px solid #eee;
        }
      }
    }

    .material_pagination {
      width: 100%;
      height: 10%;
      border-right: 1px solid #eee;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 30px;
    }
  }
}

.footer_container {
  width: 100%;
  height: 55px;
  line-height: 55px;
  border-top: 1px solid #eee;
  display: flex;
  .footer_info,
  .footer_operate,
  .footer_sure_or_close {
    width: 33%;
    line-height: 55px;
  }

  .footer_info {
    padding-left: 20px;
  }

  .footer_sure_or_close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    .sure {
      margin-left: 10px;
    }
  }
}

.ant-modal-body {
  padding: 0px !important;
}

/* 滚动槽 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
