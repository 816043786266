.product-info-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .image-info-container {
    width: 50%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }

  .other-info-container {
    width: 50%;
    text-align: left;
    margin-left: 20px;
  }
}

.display-flex-align-left {
  display: flex;
  justify-content: flex-start;

  .imageContainer {
    width: 75px;
    height: 75px;
  }

  .materialContainer {
    margin-left: 20px;
  }
}

.hideStatus {
  color: white !important;
  background: #3399ff !important;
  border: none !important;
}

.showStatus {
  color: white !important;
  background: #999999 !important;
  border: none !important;
}

.bottom-submit-and-back {
  width: 86.8%;
  height: 40px;
  margin-top: 40px;
  // position: fixed;
  // bottom:0px;
  display: flex;
  justify-content: center;
  align-items: center;
  // box-shadow: 0px -5px 5px #dddddd;
  background: white;
}
