.display-area {
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  display: table-cell;
  border: 1px solid #d9d9d9;
  background-color: #f5f5f6;
  border-style: dashed;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;

  .display-images {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.display-flex-align-left {
  display: flex;
  justify-content: flex-start;

  .imageContainer {
    width: 75px;
    height: 75px;
    border: 1px dashed #d9d9d9;
    background-color: #f5f5f6;
  }

  .materialContainer {
    margin-left: 20px;
  }
}
