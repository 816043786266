.hideStatus {
  color: white !important;
  background: #3399ff !important;
  border: none !important;
}

.showStatus {
  color: white !important;
  background: #999999 !important;
  border: none !important;
}

.display-flex-align-left {
  display: flex;
  justify-content: flex-start;

  .imageContainer {
    width: 75px;
    height: 75px;
  }

  .materialContainer {
    margin-left: 20px;
  }
}

.dispaly-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  .imageContainer {
    width: 104px;
    height: 104px;
  }
  .logoImageContainer {
    width: 115px;
    height: 50px;
  }
  .otherProductInfo {
    margin-left: 5px;
    .productNameContainer {
      height: 50%;
      align-items: center;
    }

    .productPriceContainer {
      height: 50%;
      align-items: center;
    }
  }
}
